import React from 'react';

import LatestUpdatesSection, {
  LayoutVariation,
} from 'components/Homepage/LatestUpdatesSection';
import { CTA, IGatsbyImage } from 'utils/types';

type Markdown = {
  childMarkdownRemark: {
    html: string;
  };
};

export interface ContentAndFullWidthImageProps {
  content: {
    slug: string;
    title: string;
    headline: string;
    subHeadline: string;
    subHeadlineDescription?: Markdown;
    description: Markdown;
    mainCta: CTA;
    desktopImages: IGatsbyImage[];
    mobileImages: IGatsbyImage[];
    componentVariation?: string;
    backgroundColor?: 'Primary' | 'Secondary';
  };
}

export const LatestUpdatesContainer: React.FC<ContentAndFullWidthImageProps> =
  ({ content }) => {
    if (!content) {
      return null;
    }

    const {
      slug,
      title,
      headline,
      subHeadline,
      subHeadlineDescription,
      description,
      mainCta,
      desktopImages: rawDesktopImages,
      mobileImages: rawMobileImages,
      componentVariation = 'Default',
      backgroundColor,
    } = content;

    const desktopImage = rawDesktopImages ? rawDesktopImages[0] : undefined;
    const mobileImage = rawMobileImages ? rawMobileImages[0] : undefined;

    // Eww. We need to cast the string to an Enum, though.
    const layoutVariation =
      LayoutVariation[componentVariation as keyof typeof LayoutVariation];

    return (
      <LatestUpdatesSection
        uid={slug}
        title={title}
        headline={headline}
        subHeadline={subHeadline}
        subHeadlineDescription={
          subHeadlineDescription?.childMarkdownRemark?.html
        }
        description={description?.childMarkdownRemark?.html}
        mainCta={mainCta}
        desktopImage={desktopImage}
        mobileImage={mobileImage}
        layoutVariation={layoutVariation}
        backgroundColor={backgroundColor}
        showDescriptionOnMobile
      />
    );
  };

export default LatestUpdatesContainer;
